import { LanguageType } from '@/__generated__/gateway/graphql';

const languageTypeEntries = Object.entries(LanguageType);

// getLanguageType - Overloading (differentiating response types)
export function getLanguageType(locale: string): LanguageType | undefined;
export function getLanguageType(
  locale: string,
  fallback: LanguageType
): LanguageType;

// getLanguageType - Implementation
export function getLanguageType(
  locale: string,
  fallback?: LanguageType
): LanguageType | undefined {
  const target = languageTypeEntries.find(([, value]) => value === locale)?.[1];
  return target ?? fallback;
}
